import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,HttpBackend,
} from "@angular/common/http";

import { InstantUcApiModels } from "../interface/Api/i.api-response";
import { environment } from "@env";

import { catchError } from "rxjs/operators";
import { Observable, throwError, } from "rxjs";
import { GlobalService } from "@Globalservice";

@Injectable({
  providedIn: "root",
})
export class HttpService {

  private httpWithoutInterceptor: HttpClient;
  constructor(
    private router: Router,
    private http: HttpClient,
    private globalService: GlobalService,
    private httpBackend: HttpBackend
    
  ) {
    this.httpWithoutInterceptor = new HttpClient(httpBackend);
  }

  LoginPost(
    url: string,
    body: any,
    loader = false,
    toast: boolean = true
  ): Observable<InstantUcApiModels.InstantUcApiResponse> {
    this.globalService.loader = true;
    return this.http
      .post<InstantUcApiModels.InstantUcApiResponse>(
        environment.API_BASE_PATH + url,
        body,
        { reportProgress: true }
      )
      .pipe(
        catchError((err: HttpErrorResponse) => this.handleError(err, toast))
      );
  }

  post(
    url: string,
    body: any,
    loader = false,
    toast: boolean = true
  ): Observable<InstantUcApiModels.InstantUcApiResponse> {
    const API_BASE_PATH: any = JSON.parse(localStorage.getItem("api_baseurl"));
    this.globalService.loader = true;
    return this.http
      .post<InstantUcApiModels.InstantUcApiResponse>(
        API_BASE_PATH.APIBasePath + "/" + url,
        {
          ...body,
          Username: localStorage.getItem("InstantUcInfo")?JSON.parse(localStorage.getItem("InstantUcInfo")).Username:body.Username??"",
        },
        { reportProgress: true }
      )
      .pipe(
        catchError((err: HttpErrorResponse) => this.handleError(err, toast))
      );
  }

  postUnauthrize(
    url: string,
    body: any,
    loader = false,
    toast: boolean = true
  ): Observable<InstantUcApiModels.InstantUcApiResponse> {
    const API_BASE_PATH: any = JSON.parse(localStorage.getItem("api_baseurl"));
    this.globalService.loader = true;
    body.auth = "false";
    return this.http
      .post<InstantUcApiModels.InstantUcApiResponse>(
        API_BASE_PATH.APIBasePath + url,
        body
      )
      .pipe(
        catchError((err: HttpErrorResponse) => this.handleError(err, toast))
      );
  }

  postWithoutLoader(
    url: string,
    body: any,
    loader = false,
    toast: boolean = true
  ): Observable<InstantUcApiModels.InstantUcApiResponse> {
    const API_BASE_PATH: any = JSON.parse(localStorage.getItem("api_baseurl"));
    // setTimeout(() => {
    this.globalService.loader = false;
    // }, 300);
    return this.http
      .post<InstantUcApiModels.InstantUcApiResponse>(
        API_BASE_PATH.APIBasePath + url,
        {...body,Username: localStorage.getItem("InstantUcInfo")?JSON.parse(localStorage.getItem("InstantUcInfo")).Username:body.Username??""}
      )
      .pipe(
        catchError((err: HttpErrorResponse) => this.handleError(err, toast))
      );
  }

  get(
    url: string,
    params?: any,
    loader = false,
    toast = true
  ): Observable<Response> {
    if (loader) {
      // this.ls.show();
    }
    const API_BASE_PATH: any = JSON.parse(localStorage.getItem("api_baseurl"));
    params ={
      ...params,
      Username: localStorage.getItem("InstantUcInfo")?`${JSON.parse(localStorage.getItem("InstantUcInfo")).Username}`:params.Username??"",
    }

    return this.http
      .get<Response>(API_BASE_PATH.APIBasePath + url, {params} )
      .pipe(
        catchError((err: HttpErrorResponse) => this.handleError(err, toast))
      );
  }

  //getMapLocation(url: string): Observable<Response> {
  //   this.globalService.loader = true;
  //   return this.http
  //     .get<Response>(url)
  //     .pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
  // }

  getMapLocation(url: string): Observable<any> {
    return this.httpWithoutInterceptor.get<Response>(url)
      .pipe(catchError((err: HttpErrorResponse) => throwError(err)));
  }

  downloadFile(url): Observable<any>{
    const httpOptions:any = {
      // headers: new HttpHeaders({
      //   'Accept': 'image/*'
      // }),
      responseType: 'Blob', // Set the response type to Blob
    };
    return this.httpWithoutInterceptor.get(url,httpOptions);
      //.pipe(catchError((err: HttpErrorResponse) => throwError(err)));
  }

  // *** spacial method for local master data
  getMaster(url: string): Observable<Response> {
    return this.http
      .get<Response>(url)
      .pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
  }

  getLocal(url: string): Observable<any> {
    return this.http
      .get(url)
      .pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
  }

  patch(
    url: string,
    body?: any,
    loader = false,
    toast?: boolean
  ): Observable<any> {
    if (loader) {
      // this.ls.show();
    }
    return this.http
      .patch(environment.API_BASE_PATH + url, body)
      .pipe(
        catchError((err: HttpErrorResponse) => this.handleError(err, toast))
      );
  }

  put(
    url: string,
    body?: any,
    loader = false,
    toast: boolean = true
  ): Observable<Response> {
    if (loader) {
      // this.ls.show();
    }
    return this.http
      .put<Response>(environment.API_BASE_PATH + url, body)
      .pipe(
        catchError((err: HttpErrorResponse) => this.handleError(err, toast))
      );
  }

  delete(url: string, query?: any, loader = false): Observable<Response> {
    if (loader) {
      // this.ls.show();
    }
    return this.http
      .delete<Response>(environment.API_BASE_PATH + url, { params: query })
      .pipe(
        catchError((err: HttpErrorResponse) => this.handleError(err, true))
      );
  }

  MultipartFormRequest(
    url: string,
    body: any,
    loader = false,
    toast: boolean = true
  ): Observable<InstantUcApiModels.InstantUcApiResponse> {
    if (loader) {
      // this.ls.show();
    }
    const header = new HttpHeaders({
      "Content-Type": "multipart/form-data",
    });
    const API_BASE_PATH: any = JSON.parse(localStorage.getItem("api_baseurl"));
    this.globalService.loader = true;

    return this.http
      .post<InstantUcApiModels.InstantUcApiResponse>(
        API_BASE_PATH.APIBasePath + "/" + url,
        body
      )
      .pipe(
        catchError((err: HttpErrorResponse) => this.handleError(err, toast))
      );
  }

  private handleError(err: HttpErrorResponse, toastView?: boolean) {
    if (toastView) {
      if (err.message) {
        // this.toast.error(err.message);
      }
    }
    
    console.log(err, "error handle----");
    if (err.status == 401) {
      localStorage.clear();
      this.router.navigateByUrl("/Auth");
    } else {
      return throwError(err);
    }
  }

  postForSocketApi(
    url: string,
    body: any,
    loader = false,
    toast: boolean = true
  ): Observable<any> {
    let socketUrl = JSON.parse(localStorage.getItem("api_baseurl")).SocketURL;
    return this.http.post<any>(socketUrl + url, body).pipe(
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err, toast);
      })
    );
  }





  getForSocketApi(url: string, toast: boolean = true): Observable<any> {
    let socketUrl = JSON.parse(localStorage.getItem("api_baseurl")).SocketURL;
    return this.http.get<any>(socketUrl + url).pipe(
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err, toast);
      })
    );
  }

  getHq( url: string,
    params?: any,
    loader = false,
    toast = true
  ): Observable<Response> {
    if (loader) {
      // this.ls.show();
    }
    
   
    let header = new HttpHeaders().set(
      "Authorization" ,
      environment.API_KEY).set(
      "UserName" , "") 

    return this.http
      .get<Response>(environment.API_BASE_PATH + url, {headers: header , params} )
      .pipe(
        catchError((err: HttpErrorResponse) => this.handleError(err, toast))
      );
  }

  getDepartmentHours( url: string,
    params?: any,
    loader = false,
    toast = true
  ): Observable<Response> {
    if (loader) {
      // this.ls.show();
    }
   
    let header = new HttpHeaders().set(
      "Authorization" ,
      localStorage.getItem("access_token")).set(
      "UserName" , "") 

    return this.http
      .get<Response>(environment.API_BASE_PATH + url, {headers: header , params} )
      .pipe(
        catchError((err: HttpErrorResponse) => this.handleError(err, toast))
      );
  }

  postHq(
    url: string,
    body: any,
    loader = false,
    toast: boolean = true
  ): Observable<InstantUcApiModels.InstantUcApiResponse> {
    this.globalService.loader = true;
    return this.http
      .post<InstantUcApiModels.InstantUcApiResponse>(
     environment.API_BASE_PATH + url, body,
          // Username: localStorage.getItem("InstantUcInfo")?JSON.parse(localStorage.getItem("InstantUcInfo")).Username:body.Username??"",
        { reportProgress: true }
      )
      .pipe(
        catchError((err: HttpErrorResponse) => this.handleError(err, toast))
      );
  }
}
